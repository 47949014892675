import React from 'react';
import Sidebar from '../Sidebar';
import SideBarGerencial from '../SideBarGerencial';
import SideBarFinancas from '../SideBarFinancas/SideBarFinancas';
import SideBarAppCli from '../SideBarAppCli/SideBarAppCli';
import SideBarAdmAppCli from '../SideBarAdmAppCli';
import SideBarFrotas from '../SideBarFrotas';
import SideBarCRM from '../SideBarCRM';
import {
  AMODULO_FINANCAS, AMODULO_GERENCIAL, AMODULO_RECEPTIVO,  AMODULO_SISAREPASSENGER, AMODULO_ADMAPPCLI,
  AMODULO_FROTAS,
  AMODULO_CRM
} from 'consts';


const ChosenBar = (props) => {
  let { onClose } = props;
  let { variant } = props;
  let { modulo } = props;
  let { open } = props;


  // Declare os métodos handleSidebarOpen e handleSidebarClose aqui
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  //  const shouldOpenSidebar = isDesktop ? true : openSidebar;



  let componenteRenderizado;

  switch (parseInt(modulo)) {
    case AMODULO_RECEPTIVO:
      componenteRenderizado =
        <Sidebar
          onClose={onClose}
          open={open}
          variant={variant}
        />;
      break;

    case AMODULO_FINANCAS:
      componenteRenderizado =
        <SideBarFinancas
          onClose={onClose}
          open={open}
          variant={variant}
        />;
      break;

    case AMODULO_GERENCIAL:
      componenteRenderizado =
        <SideBarGerencial
          onClose={onClose}
          open={open}
          variant={variant}
        />;
      break;

    case AMODULO_SISAREPASSENGER:
      componenteRenderizado =
        <SideBarAppCli
          onClose={onClose}
          open={open}
          variant={variant}
        />;
      break;
    case AMODULO_ADMAPPCLI:
      componenteRenderizado =
        <SideBarAdmAppCli
          onClose={onClose}
          open={open}
          variant={variant}
        />;
      break;

    case AMODULO_FROTAS:
      componenteRenderizado =
        <SideBarFrotas
          onClose={onClose}
          open={open}
          variant={variant}
        />;
      break;

    case AMODULO_CRM:
      componenteRenderizado =
        <SideBarCRM
          onClose={onClose}
          open={open}
          variant={variant}
        />;
      break;

    default:
      componenteRenderizado = <div>nenhum menu selecionado</div>;
      break;
  }


  return (
    <div>
      {componenteRenderizado}
    </div>
  );
};

export default ChosenBar;