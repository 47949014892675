import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  Dashboard as DashboardView,
  UserList as UserListView,
  Typography as TypographyView,
  NotFound as NotFoundView,
  Login as LoginView,
  Logout as LogoutView,
  OSList as OSListView,
  OS as OS,
  ClienteList as ClienteListView,
  SetorList as SetorListView,
  TransacaoFinanceiraList as TransacaoFinanceiraListView,
  BancoList as BancoListView,
  TipoDocumentoList as TipoDocumentoView,
  HistoricoBancarioList as HistoricoBancarioView,
  FeriadoList as FeriadoListView,
  PaisList as PaisListView,
  FechamentoFinanceiroForm,
  ContaCorrenteList as ContaCorrenteListView,
  CategoriaServicoList as CategoriaServicoListView,
  MotivoCancelamentoList as MotivoCancelamentoView,
  ServicoList as ServicoListView,
  MapaOperacao as MapaOperacao,
  FormPax,
  AdmSisarePassengerForm,
  MarcaVeiculoList,
  AdmSisarePassengerRoom, 
  AdmSisarePassengerGuidance
} from './views';

import { MenuProducao as MenuProducaoView } from './views';
import { MenuOperacional as MenuOperacionalView } from './views';
import { MenuFinanceiro as MenuFinanceiroView } from './views';
import { MenuAdministrativo as MenuAdministrativoView } from './views';

import { MenuProgramacao as MenuProgramacaoView } from './views';
import { FaleConosco as FaleConoscoView } from './views';
import { Hospedagem as hospedagemView } from './views';

import { LoginAppCli as LoginAppCliView } from './views';

import SetorForm from 'views/Financas/Setor/SetorForm';
import TransacaoFinanceiraForm from 'views/Financas/TransacaoFinanceira/TransacaoFinanceiraForm';
import BancoForm from 'views/Financas/Banco/BancoForm';
import TipoDocumentoForm from 'views/Financas/TipoDocumento/TipoDocumentoForm';
import HistoricoBancarioForm from 'views/Financas/HistoricoBancario/HistoricoBancarioForm';
import FeriadoForm from 'views/Geral/Feriado/FeriadoForm';
import PaisForm from 'views/Geral/Pais/PaisForm';
import FechamentoFinanceiroList from 'views/Financas/FechamentoFinanceiro/FechamentoFinanceiroList';
import ContaCorrenteForm from 'views/Financas/ContaCorrente/ContaCorrenteForm';
import CategoriaServicoForm from 'views/Front/CategoriaServico/CategoriaServicoForm';
import MotivoCancelamentoForm from 'views/Front/MotivoCancelamento/MotivoCancelamentoForm';
import ServicoForm from 'views/Front/Servico/ServicoForm';
import AdmSisarePassengerList from 'views/AdmAppCli/AdmSisarePassengerList';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/gerencial"
      />

      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />

      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/login"
      />

      {/*<RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/financas"
      /> */}

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/financas/dashboard"
      />

      <RouteWithLayout
        component={HistoricoBancarioView}
        exact
        layout={MainLayout}
        path="/financas/historicosbancarios"
      />

      <RouteWithLayout
        component={HistoricoBancarioForm}
        layout={MainLayout}
        path="/financas/historicosbancarios/:id"
      />

      <RouteWithLayout
        component={TipoDocumentoView}
        exact
        layout={MainLayout}
        path="/financas/tiposdocumentos"
      />

      <RouteWithLayout
        component={TipoDocumentoForm}
        layout={MainLayout}
        path="/financas/tiposdocumentos/:id"
      />

      <RouteWithLayout
        component={ContaCorrenteListView}
        exact
        layout={MainLayout}
        path="/financas/contascorrentes"
      />

      <RouteWithLayout
        component={ContaCorrenteForm}
        layout={MainLayout}
        path="/financas/contascorrentes/:id"
      />

      <RouteWithLayout
        component={TransacaoFinanceiraListView}
        exact
        layout={MainLayout}
        path="/financas/transacoes"
      />

      <RouteWithLayout
        component={TransacaoFinanceiraForm}
        layout={MainLayout}
        path="/financas/transacoes/:id"
      />

      <RouteWithLayout
        component={BancoListView}
        exact
        layout={MainLayout}
        path="/financas/bancos"
      />

      <RouteWithLayout
        component={BancoForm}
        layout={MainLayout}
        path="/financas/bancos/:id"
      />

      <RouteWithLayout
        component={SetorListView}
        exact
        layout={MainLayout}
        path="/financas/setores"
      />

      <RouteWithLayout
        component={SetorForm}
        //exact
        layout={MainLayout}
        path="/financas/setores/:id"
      />

      <RouteWithLayout
        component={FeriadoListView}
        exact
        layout={MainLayout}
        path="/financas/feriados"
      />

      <RouteWithLayout
        component={FeriadoForm}
        //exact
        layout={MainLayout}
        path="/financas/feriados/:id"
      />

      <RouteWithLayout
        component={PaisListView}
        exact
        layout={MainLayout}
        path="/financas/paises"
      />

      <RouteWithLayout
        component={PaisForm}
        //exact
        layout={MainLayout}
        path="/financas/paises/:id"
      />

      <RouteWithLayout
        component={FechamentoFinanceiroForm}
        //exact
        layout={MainLayout}
        path="/financas/fechamentofinanceiro"
      />

      <RouteWithLayout
        component={FechamentoFinanceiroForm}
        //exact
        layout={MainLayout}
        path="/financas/fechamentofinanceiro"
      />

      <RouteWithLayout
        component={FechamentoFinanceiroList}
        exact
        layout={MainLayout}
        path="/financas/fechamentosfinanceiros"
      />

      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/crm"
      />

      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/front"
      />

      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/frotas"
      />
      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/estoque"
      />

      <RouteWithLayout
        component={OSListView}
        exact
        layout={MainLayout}
        path="/front/oss"
      />

      <RouteWithLayout
        component={OS}
        layout={MainLayout}
        path="/front/os/:id"
      />


      <RouteWithLayout
        component={FormPax}
        exact
        layout={MainLayout}
        path="/front/pax/:id"
      />


      <RouteWithLayout
        component={MapaOperacao}
        exact
        layout={MainLayout}
        path="/front/mapaoperacao"
      />

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/front/dashboard"
      />

      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/front/users"
      />

      <RouteWithLayout
        component={CategoriaServicoListView}
        exact
        layout={MainLayout}
        path="/front/categoriasservicos"
      />

      <RouteWithLayout
        component={CategoriaServicoForm}
        layout={MainLayout}
        path="/front/categoriasservicos/:id"
      />

      <RouteWithLayout
        component={ServicoListView}
        exact
        layout={MainLayout}
        path="/front/servicos"
      />

      <RouteWithLayout
        component={ServicoForm}
        layout={MainLayout}
        path="/front/servicos/:id"
      />

      <RouteWithLayout
        component={MotivoCancelamentoView}
        exact
        layout={MainLayout}
        path="/front/motivoscancelamentos"
      />

      <RouteWithLayout
        component={MotivoCancelamentoForm}
        layout={MainLayout}
        path="/front/motivoscancelamentos/:id"
      />

      <RouteWithLayout
        component={ClienteListView}
        exact
        layout={MainLayout}
        path="/front/clientes"
      />

      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/gerencial"
      />
      <RouteWithLayout
        component={MenuProducaoView}
        exact
        layout={MainLayout}
        path="/gerencial/menuproducao"
      />

      <RouteWithLayout
        component={MenuOperacionalView}
        exact
        layout={MainLayout}
        path="/gerencial/menuoperacional"
      />

      <RouteWithLayout
        component={MenuFinanceiroView}
        exact
        layout={MainLayout}
        path="/gerencial/menufinanceiro"
      />

      <RouteWithLayout
        component={MenuAdministrativoView}
        exact
        layout={MainLayout}
        path="/gerencial/menuadministrativo"
      />

      <RouteWithLayout
        component={MenuProgramacaoView}
        exact
        layout={MainLayout}
        path="/appcli/programacao"
      />

      <RouteWithLayout
        component={hospedagemView}
        exact
        layout={MainLayout}
        path="/appcli/hospedagem"
      />

      <RouteWithLayout
        component={FaleConoscoView}
        exact
        layout={MainLayout}
        path="/appcli/faleconosco"
      />

      <RouteWithLayout
        component={LoginAppCliView}
        exact
        layout={MinimalLayout}
        path="/appcli/login"
      />

      <RouteWithLayout
        component={LoginAppCliView}
        exact
        layout={MinimalLayout}
        path="/appcli"
      />
      <RouteWithLayout
        component={LoginAppCliView}
        exact
        layout={MinimalLayout}
        path="/appcli/:localizador"
      />


      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/admappcli"
      />



      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/admappcli/dashboard"
      />


      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/frotas/dashboard"
      />

      <RouteWithLayout
        component={MarcaVeiculoList}
        exact
        layout={MainLayout}
        path="/frotas/marcaveiculo"
      />

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/crm/dashboard"
      />

      <RouteWithLayout
        component={AdmSisarePassengerForm}
        layout={MainLayout}
        path="/admappcli/adm-sisare-passenger/:id"
      />


      <RouteWithLayout
        component={AdmSisarePassengerList}
        exact
        layout={MainLayout}
        path="/admappcli/adm-sisare-passenger-list"
      />


      <RouteWithLayout
        component={AdmSisarePassengerRoom}
        exact
        layout={MainLayout}
        path="/admappcli/adm-sisare-passenger-room/:id"
      />

      <RouteWithLayout
        component={AdmSisarePassengerGuidance}
        exact
        layout={MainLayout}
        path="/admappcli/adm-sisare-passenger-guidance/:id/:idpagina"
      />


      <RouteWithLayout
        component={LogoutView}
        exact
        layout={MinimalLayout}
        path="/logout"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
