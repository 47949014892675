import React, { Component } from 'react';
import { TextField, Button, Box, Dialog, DialogContent, FormControl, InputLabel, Select, FormHelperText, FormLabel, Table, TableHead, TableRow, TableCell, Typography, TableBody } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import api from '../../../services/api';
import { diff } from 'deep-diff';
import moment from 'moment';
import AlertInformation from 'components/AlertInformation/AlertInformation';
import ComboPadrao from 'views/Components/ComboPadrao';
import { ASIM, ATIPOFORNEC_GUIA, ATIPOFORNEC_MOTORISTA, ATIPOFORNEC_OUTROS, ATIPOFORNEC_TRANSPORTE } from 'consts';
import { validateField } from 'lib/functions';
import { getConfigField } from 'lib/configUtils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal/DeleteConfirmationModal';

// Definição dos estilos
const styles = theme => ({
    textField: {
        marginTop: theme.spacing(0),
        marginBottom: "0.5rem",
        marginRight: "0.5rem",
        variant: "outlined"
    },
    addButton: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        float: 'right',
    },
    modalContent: {
        maxHeight: '100vh', // Ajuste a altura máxima conforme necessário
        overflowY: 'auto', // Permite rolar verticalmente
        width: '50vh'
    },
    helperTextError: {
        color: 'red',
    }
});

class CentroDespesaFornecedorServicoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            centrodespesafornecedorservico: {
                chavefornecserv: this.props.codFornecedorServico
            },
            centrodespesafornecedorservico_original: {},
            centrosdespesa: [],
            setores: [],
            // isLoadingCentroDespesa: true,
            // isLoadingSetor: true,
            // isLoadingProjeto: true,
            // selectedIdCentroDesp: 0,
            // selectedIndexCentroDesp: -1,
            // selectedNomeCentroDesp: '',
            // isCentroDespDeleteModalOpen: false,
            errors: {},
            schema: {
                // codfornecedor: {
                //     presence: { allowEmpty: false, message: 'Fornecedor obrigatório' },
                //     length: { maximum: 10 },
                //     type: 'select-one'
                // },
                // tipofornec: {
                //     presence: { allowEmpty: false, message: 'Tipo obrigatório' },
                //     length: { maximum: 10 },
                //     type: 'select-one'
                // }
            },
            isNewRecord: false,
            openAlert: false,
            messageAlert: ''
        };
    }

    async componentDidMount() {
        let id = this.props.id;
        let centrodespesafornecedorservicoData;

        const controlaprojeto = (getConfigField('controlaprojeto', 'cfg_financas') == ASIM);        

        if (id > 0) {
            if (this.props.codFornecedorServico === undefined) {
                centrodespesafornecedorservicoData = this.props.centrodespesafornecedorservico;
            } else {
                const response = await api.get(`/fornecedorservico/centrocusto/${id}`);
                centrodespesafornecedorservicoData = response.data[0];
            }

            this.setState({
                centrodespesafornecedorservico: centrodespesafornecedorservicoData,
                centrodespesafornecedorservico_original: JSON.parse(JSON.stringify(centrodespesafornecedorservicoData)),
                isNewRecord: false
            });

            const centrosdespesa = await api.get(`/financas/centroreceitadespesa/listforchange/${centrodespesafornecedorservicoData.codcreditodebito}?tipoconta=A`);
            this.setState({ centrosdespesa: centrosdespesa.data });

            const setores = await api.get(`/setor/listforchange/${centrodespesafornecedorservicoData.codsetor}`);
            this.setState({ setores: setores.data });

            // this.loadCentroDesp();
            // this.loadSetor();
            // if (controlaprojeto) {
            //     this.loadProjeto();
            // }
        } else if (id === 'novo') {
            // this.setState({ isNewRecord: true });

            // this.setState(prevState => ({
            //     fornecedorservico: {
            //         ...prevState.fornecedorservico
            //     }
            // }));

            // const fornecedores = await api.get(`/fornecedor/active/true`);
            // this.setState({ fornecedores: fornecedores.data });
        }
    }

    handleCancel = () => {
        this.props.onClose();
    };

    OnChange = (event) => {
    //     const { fornecedorservico } = this.state;
    //     const { name, type, value } = event.target;
    //     let adjustedValue = Number(value);

    //     this.setState(prevState => ({
    //         fornecedorservico: {
    //             ...prevState.fornecedorservico,
    //             [name]: adjustedValue
    //         },
    //         errors: {
    //             ...prevState.errors,
    //             [name]: typeof adjustedValue === 'string' && adjustedValue.trim() === ''
    //         }
    //     }));

    }

    // onClickSave = () => {
    //     if (this.state.isNewRecord) {
    //         this.insereFornecedorServico();
    //     } else {
    //         this.atualizaFornecedorServico();
    //     }
    // }

    // insereFornecedorServico = async () => {
    //     const { fornecedorservico } = this.state;
    //     try {
    //         if (!(this.props.codServico === undefined)) {
    //             const response = await api.post(`/fornecedorservico`, fornecedorservico);
    //             if (response.status === 200) {
    //                 this.handleCancel();
    //                 this.props.onSave();
    //             }
    //         } else {
    //             this.handleCancel();
    //             this.props.onSave(this.state.fornecedorservico);
    //         }
    //     } catch (error) {
    //         this.handleOpenAlert(error.response.data.error);
    //     }
    // }

    // atualizaFornecedorServico = async () => {
    //     const { fornecedorservico, fornecedorservico_original } = this.state;

    //     const differences = diff(fornecedorservico_original, fornecedorservico);

    //     if (!differences) {
    //         this.handleCancel();
    //         return;
    //     }

    //     const fornecedorservicoAlterado = {};
    //     differences.forEach(d => {
    //         if (d.kind === 'E' || d.kind === 'N') {
    //             fornecedorservicoAlterado[d.path.join('.')] = d.rhs;
    //         }
    //     });

    //     try {
    //         if (!(this.props.codServico === undefined)) {
    //             const response = await api.put(`/fornecedorservico/${fornecedorservico.chave}`, fornecedorservicoAlterado);
    //             if (response.status === 200) {
    //                 this.handleCancel();
    //                 this.props.onSave();
    //             }
    //         } else {
    //             this.handleCancel();
    //             this.props.onSave(this.state.fornecedorservico);
    //         }
    //     } catch (error) {
    //         this.handleOpenAlert(error.response.data.error);
    //     }
    // }

    // validationRequiredFields = () => {
    //     const { fornecedorservico } = this.state;
    //     const { schema } = this.state;
    //     let errors = {};
    //     const fornecedorError = validateField('codfornecedor', fornecedorservico.codfornecedor, schema);
    //     if (fornecedorError) {
    //         errors.codfornecedor = fornecedorError;
    //     }
    //     const tipofornecError = validateField('tipofornec', fornecedorservico.tipofornec, schema);
    //     if (tipofornecError) {
    //         errors.tipofornec = tipofornecError;
    //     }
    //     return errors;
    // };

    // handleSubmit = () => {
    //     let errors = this.validationRequiredFields();
    //     this.setState({ errors });
    //     if (Object.keys(errors).length === 0) {
    //         this.onClickSave();
    //     }
    // };

    // handleOpenAlert = (message) => {
    //     this.setState({ openAlert: true, messageAlert: message });
    // };

    // handleCloseAlert = () => {
    //     this.setState({ openAlert: false });
    // };    

    render() {
        const { isModal, classes } = this.props;

        const { centrodespesafornecedorservico } = this.state;
        const { centrosdespesa } = this.state;
        const { setores } = this.state;
        // const { centroDesp, isLoadingCentroDespesa } = this.state;
        // const { setor, isLoadingSetor } = this.state;
        // const { projeto, isLoadingProjeto } = this.state;

        const { errors } = this.state;

        // campos configurações
        const controlaprojeto = (getConfigField('controlaprojeto', 'cfg_financas') == ASIM);

        return (
            <Dialog open={isModal} onClose={this.handleCancel} maxWidth="sm">
                <DialogContent
                // className={classes.modalContent}
                >

                    <FormControl
                        fullWidth
                        variant="outlined"
                    >
                        <ComboPadrao
                            name={'codcreditodebito'}
                            label={'Centro de receita/despesa'}
                            value={centrodespesafornecedorservico.codcreditodebito}
                            onChange={this.OnChange}
                            fieldId={'codcreditodebito'}
                            listItems={centrosdespesa}
                            fieldDescription={'desccreditodebito'}
                            helperText={errors.codcreditodebito || ''}
                            error={!!errors.codcreditodebito}
                        />
                    </FormControl>

                    <br />
                    <br />

                    <FormControl
                        fullWidth
                        variant="outlined"
                    >
                        <ComboPadrao
                            name={'codsetor'}
                            label={'Setor'}
                            value={centrodespesafornecedorservico.codsetor}
                            onChange={this.OnChange}
                            fieldId={'codsetor'}
                            listItems={setores}
                            fieldDescription={'descsetor'}
                            helperText={errors.codsetor || ''}
                            error={!!errors.codsetor}
                        />
                    </FormControl>

                    <br />
                    <br />

                    {/* <FormControl
                        variant="outlined"
                    >
                        <InputLabel
                            htmlFor="outlined-age-native-simple"
                            className={classes.inputLabel}
                        >
                            Tipo
                        </InputLabel>
                        <Select
                            fullWidth
                            className={classes.select}
                            inputProps={{
                                id: 'outlined-age-native-simple',
                                name: 'tipofornec',
                            }}
                            label="tipo"
                            native
                            onChange={this.OnChange}
                            value={fornecedorservico.tipofornec || ''}
                            style={{ color: 'black', border: 'none' }}
                        >
                            <option value={"undefined"} aria-label="Não Definido"> </option>
                            <option
                                key={ATIPOFORNEC_GUIA}
                                value={ATIPOFORNEC_GUIA}
                                style={{ color: 'black', backgroundColor: 'inherit' }}
                            >
                                Guia
                            </option>
                            <option
                                key={ATIPOFORNEC_TRANSPORTE}
                                value={ATIPOFORNEC_TRANSPORTE}
                                style={{ color: 'black', backgroundColor: 'inherit' }}
                            >
                                Transportista
                            </option>
                            <option
                                key={ATIPOFORNEC_MOTORISTA}
                                value={ATIPOFORNEC_MOTORISTA}
                                style={{ color: 'black', backgroundColor: 'inherit' }}
                            >
                                Motorista
                            </option>
                            <option
                                key={ATIPOFORNEC_OUTROS}
                                value={ATIPOFORNEC_OUTROS}
                                style={{ color: 'black', backgroundColor: 'inherit' }}
                            >
                                Outros
                            </option>
                        </Select>
                        <FormHelperText className={errors.tipofornec ? classes.helperTextError : ''}>
                            {errors.tipofornec}
                        </FormHelperText>
                    </FormControl> */}                

                    <br />
                    <br />

                    <Box style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.handleSubmit}
                            style={{ marginRight: '5px' }}
                        >
                            Salvar
                        </Button>

                        {isModal && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.handleCancel}
                            >
                                Cancelar
                            </Button>
                        )}
                    </Box>

                    <div>
                        {/* <AlertInformation
                            open={this.state.openAlert}
                            handleClose={this.handleCloseAlert}
                            message={this.state.messageAlert}
                        /> */}
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(CentroDespesaFornecedorServicoForm);