import AppBar from '@material-ui/core/AppBar';
import api from 'services/api';
import React, { Component } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ImageIcon from '@material-ui/icons/Image';
import PaletteIcon from '@material-ui/icons/Palette';
import BuildIcon from '@material-ui/icons/Build';
import ExtensionIcon from '@material-ui/icons/Extension';
import PublicIcon from '@material-ui/icons/Public';
import Box from '@material-ui/core/Box';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import { Modal } from '@material-ui/core';
import TraducaoSisarePassengerForm from './Traducao/TraducaoSisarePassengerForm.js';

import {
  CardContent, FormLabel, Paper, Checkbox, FormControl,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, Button
} from '@material-ui/core';

import FormGroup from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';
import { ASIM, ANAO } from '../../consts.js';
import { diff } from 'deep-diff';
import { formatDateForInput } from 'lib/functions';
import ButtonA2X from 'views/Components/Bottombar';
import { loadConfigurations, getConfigField, loadCompany } from 'lib/configUtils';
import { validateField } from 'lib/functions';
import i18n from 'translations/langEngine';
import { CommonTranslations } from 'translations/translationKeys'
import { ChromePicker } from 'react-color';
import { Switch, FormControlLabel } from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditIcon from '@material-ui/icons/Edit';

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{ padding: 8 * 3 }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


// Definição dos estilos
const styles = theme => ({
  textField: {
    marginTop: theme.spacing(0), // Margem superior
    marginBottom: "0.5rem", // Margem inferior
    marginRight: "0.5rem", // Margem direita
  },
  addButton: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    float: 'right', // alinhamento a direita
  },
  textarea: {
    width: '100%',
    height: '100px',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
  },
  dialogContent: {
    padding: theme.spacing(2),
  },

});

class AdmSisarePassengerForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pagina: {},
      pagina_original: {},
      activetab: 0,
      isNewRecord: false,
      isView: false,
      openAlert: false,
      isLoadingCity: true,
      isLoadingCliente: false,
      translations: {}, // Armazenará as traduções
      termos: [],
      errors: {},
      color: "#000000", // Estado para armazenar a cor selecionada
      showPicker: false, // Estado para controlar visibilidade do seletor
      schema: {
        titular: {
          presence: { allowEmpty: false, message: 'Título da página obrigatório' },
          length: { maximum: 100 },
          type: 'string'
        },

      },
      /*
      useStyles : makeStyles(theme => ({
        root: {
          backgroundColor: theme.palette.background.default,
          height: '100%'
        },
        grid: {
          height: '100%'
        },
        quoteContainer: {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        quote: {
          backgroundColor: theme.palette.neutral,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/images/auth.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },
        quoteInner: {
          textAlign: 'center',
          flexBasis: '600px'
        },
        quoteText: {
          color: theme.palette.white,
          fontWeight: 300
        },
        name: {
          marginTop: theme.spacing(3),
          color: theme.palette.white
        },
        bio: {
          color: theme.palette.white
        },
        contentContainer: {},
        content: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        contentHeader: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: theme.spacing(5),
          paddingBototm: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        },
        logoImage: {
          marginLeft: theme.spacing(4)
        },
        contentBody: {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
          }
        },
        form: {
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 125,
          flexBasis: 700,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        },
        title: {
          marginTop: theme.spacing(3)
        },
        socialButtons: {
          marginTop: theme.spacing(3)
        },
        socialIcon: {
          marginRight: theme.spacing(1)
        },
        sugestion: {
          marginTop: theme.spacing(2)
        },
        signInButton: {
          margin: theme.spacing(2, 0)
        },
        alert: {
          color: '#000000',
        },
        textField: {
          marginTop: theme.spacing(2), // Mantém o espaçamento superior
          marginBottom:"0.5rem", 
          marginRight:"1rem"
          //marginTop:'2rem',
          //marginLeft: '1rem',
          //marginBottom: '1rem', // Adiciona o espaçamento inferior de 1 rem
    //      marginRight: '1rem', // Adiciona o espaçamento inferior de 1 rem
          //width: '100%', // Centraliza o campo dentro do container
        },
        
      })), */

    };
  }

  async componentDidMount() {
    this.setState({ isLoadingCliente: true });  // Inicia o carregamento
    this.performsTranslation();
    const { location } = this.props;
    const currentPath = location.pathname;

    const { id } = this.props.match.params;
    if (id > 0) {
      const response = await api.get(`/appcli/pages/${id}`);
      const paginaData = response.data[0];
      const pagina_original = paginaData;

      this.setState({
        pagina: paginaData,
        pagina_original: pagina_original,
        activeTab: '1',
        isNewRecord: false,
        isView: (currentPath.includes('/view'))
      }
      );


      this.setState({
        activeTab: '1',
        isNewRecord: false,
      });

    }
    else
      // se for novo registro
      if (id === 'novo') {
        this.setState({
          activeTab: '1',
          isNewRecord: true
        });
        this.state.isNewRecord = true;
        const { pagina } = this.state;
      }
  }

  handleChangeTab = (event, value) => {
    this.setState({ activetab: value });
    if (value === 1) {
      //this.loadProcedimentosOS();
    }
    else if (value === 2) {
    }
    else if (value === 3) {
      this.loadTermos();
    }
    else if (value == 4) {
    }
  };

  /* usado para não exibir componente*/
  getNotDisplay(show) {
    if (show) {
      return 'none'
    }
    else
      return ''
  }

  /* insere nova página */
  insereDados = async () => {
    const { pagina } = this.state;
    pagina['codusuario'] = parseInt(sessionStorage.getItem('codUser'), 10);
    pagina['codempresa'] = parseInt(sessionStorage.getItem('codEmpresa'), 10);
    pagina['codpdv'] = parseInt(sessionStorage.getItem('codPDV'), 10);
    this.setState({ pagina: pagina });

    try {
      console.log('vai acionar o admappcli/page...')
      const response = await api.post(`/admappcli/page`, this.state.pagina);

      if (response.status === 200) {
        console.log('resposta da inserção: ' + JSON.stringify(response.data))
        //error.response.data.error
        alert('Página inserida com sucesso');
        //this.handleOpenAlert('OS inserida com sucesso! Número da OS: ' + response.data.nroos);  
        //this.props.history.push('/front/oss');

        this.props.history.push('/admappcli/adm-sisare-passenger-list');
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }


  /*  atualiza dados de OS - PUT */
  atualizaDados = async () => {
    const { pagina, pagina_original } = this.state;
    // Identificar as diferenças entre os objetos
    const differences = diff(pagina_original, pagina);

    //console.log('o que está em diferenças: ' + JSON.stringify(differences));
    /* se não há diferenças entao apenas fecha*/
    if (!differences) {
      //console.log("Nenhuma alteração detectada.");
      this.props.history.push('/admappcli/adm-sisare-passenger-list');
      return;
    }
    // Filtrar as diferenças para criar um novo objeto com as alterações
    const paginaAlterada = {};
    differences.forEach(d => {
      if (d.kind === 'E' || d.kind === 'N') { // 'E' para editado, 'N' para novo
        paginaAlterada[d.path.join('.')] = d.rhs; // d.path é o caminho da propriedade
      }
    });

    // Inclua campos obrigatórios, se necessário
    paginaAlterada['codusuarioregistrou'] = parseInt(sessionStorage.getItem('codUser'), 10);
    paginaAlterada['codempresa'] = parseInt(sessionStorage.getItem('codEmpresa'), 10);

    try {
      console.log('o que está em osAlterada: ' + JSON.stringify(paginaAlterada));
      const response = await api.put(`/admappcli/page/${pagina.id}`, paginaAlterada);
      if (response.status === 200) {
        this.props.history.push('/admappcli/adm-sisare-passenger-list');
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.message);
      //      error.response.data.error
    }
  }

  /* carrega termos traducao módulo: sisare-passenger */
  async loadTermos() {
    console.log('carrega termos..');
    const params = `tbtraducaoweb.chave=_like_sis-passenger`;
    const url = `/traducao/termos?${params}`;

    const termos = await api.get(url);

    this.setState({ termos: termos.data });

  }

  OnChange = (event) => {
    const { name, type, value, checked } = event.target;
    const { pagina, errors } = this.state;
    let adjustedValue = value;

    console.log('name: ' + name + ' type: ' + type + ' value: ' + value);

    switch (type) {
      case 'checkbox':
        adjustedValue = (name === 'audio_guia') ? (checked ? ASIM : ANAO) : (checked ? ASIM : ANAO);
        break;
      case 'select-one':
        adjustedValue = (name === 'regimehosp') ? value : Number(value);
        break;
      default:
        adjustedValue = value;
        break;
    }

    this.setState(prevState => ({
      pagina: {
        ...prevState.pagina,
        [name]: adjustedValue
      },
      errors: {
        ...prevState.errors,
        [name]: typeof adjustedValue === 'string' && adjustedValue.trim() === ''
      }
    }));
  };

  /* Click salvar */
  onClickSave = () => {
    if (this.state.isNewRecord) {
      this.insereDados()
    }
    else {
      this.atualizaDados();
    }
  }


  /* Click cancelar */
  onClickCancel = () => {
    this.props.history.push('/admappcli/adm-sisare-passenger-list');
  }


  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    //this.setState({ openAlert: false });
    this.setState({ openAlert: false });
    if (this.state.onCloseCallback) {
      this.state.onCloseCallback();
    }
  }

  /* Envio de dados */
  handleSubmit = () => {
    let errors = {};
    errors = this.validationRequiredFields();
    console.log('o que está em errors: ' + JSON.stringify(errors));
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      // Enviar os dados do formulário
      this.onClickSave();
    }
  };

  /* valida campos obrigatórios */
  validationRequiredFields = () => {
    const { pagina } = this.state;
    const { schema } = this.state;
    let errors = {};

    /* valida Página */
    const titulo_paginaError = validateField('titular', pagina.titulo_pagina, schema);
    if (titulo_paginaError) {
      errors.titulo_pagina = titulo_paginaError;
    }


    /* mensagem geral de campos obrigatórios */
    /* colocar campos obrigatórios com destaque em vermelho controle na tela  validar acima */
    if (!(Object.keys(errors).length === 0)) {
      //console.log('o tamanho do errors: ' + errors + ' lenght errors: ' + errors.length);
      //console.log('errors: ' + JSON.stringify(errors));
      this.handleOpenAlert('Verifique os campos obrigatórios!');
      return errors;
    }


    //error = fieldSchema.presence.message;
    // Adicione validação para outros campos se necessário
    return errors;
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  /* fecha janela de confirmação exclusão de pax */
  openPaxDeleteModal = (idpax, nomepax) => {
    this.setState({ selectedIdPax: idpax });
    this.setState({ selectedNomePax: nomepax });
    this.setState({ isPaxDeleteModalOpen: true });
  };

  /* fecha janela de confirmação exclusão de pax */
  closePaxDeleteModal = () => {
    this.setState({ isPaxDeleteModalOpen: false });
  };
  /* open form pax modal */
  openTraducaoModal = (id = 'novo') => {
    //console.log('passou o id pax: ' + id);
    let traducaoEncontrado = {};
    if (this.state.isNewRecord) {
      if (!(id === 'novo')) {
        const idpaxProcurado = parseInt(id, 10);
        traducaoEncontrado = this.state.termos.find(traducao => traducao.chave === chaveProcurado);
      }
    }

    this.setState({
      open: true,
      selectedChaveTraducao: id, // Define o ID do Pax que será editado, ou 'novo' para criar
      selectedTraducao: traducaoEncontrado
    });
  };

  /* Realiza a tradução */
  async performsTranslation() {
    const termsNeeded = [CommonTranslations.BUTTON_SAVE,
    CommonTranslations.BUTTON_CANCEL,
    CommonTranslations.CHECKBOX_INATIVO,
    CommonTranslations.TABSHEET_GERAL];
    // Lista de termos da página
    const translations = await i18n.fetchTerms(termsNeeded);
    this.setState({ translations });
  }

  // Atualiza a cor ao escolher no seletor
  handleColorChange = (newColor) => {
    this.setState({ color: newColor.hex });
  };

  closePassengerScreen = () => {
    this.setState({ showPassengerScreen: false });
  };

  handleInputChange = (event) => {
    const value = event.target.value;
    if (/^#[0-9A-F]{6}$/i.test(value)) { // Valida se é um hexadecimal
      this.setState({ color: value });
    } else {
      this.setState({ color: "#000000" }); // Cor padrão se for inválido
    }
  };
  // Mostra o seletor de cores
  showColorPicker = () => {
    this.setState({ showPicker: true });
  };

  // Esconde o seletor de cores
  hideColorPicker = () => {
    this.setState({ showPicker: false });
  };


  // Método para atualizar a lista de pax
  updateTraducaoList = async (traducao) => {
    const traducaooff = traducao;
    try {
      //const paxos = await api.get(`/pax/os/${this.state.os.codos}`);
      if (!(this.state.isNewRecord)) {
        const termos = await api.get(`/traducao/xxx/${this.state.traducao.chave}?sort=tbpaxgeral.seqpaxos`);
        if (termos.status === 200) {
          this.setState({ termos: termos.data });
        }
      }
      else {
        //console.log('é alteração sem inserção -- off');
        const { termos } = this.state; // lista de pax
        const index = termos.findIndex(termo => termo.chave === termooff.chave);

        if (index !== -1) {
          // Cria uma nova lista com o objeto substituído
          const novaListaTermos = [
            ...termos.slice(0, index),
            termooff,
            ...termos.slice(index + 1)
          ];

          // Atualiza o estado com a nova lista
          this.setState({ termos: novaListaTermos });
        }
      }

      //console.log('vai carregar loadcitypax no updatePaxList')
      this.loadCityPax();

    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }

  render() {
    //const useStyles = this.state.useStyles;
    const { classes } = this.props; // nome padrão onde vem estilo do materialui: classes precisa declarar 
    const { translations } = this.state;
    const { errors } = this.state;
    const { activetab } = this.state;
    const { pagina } = this.state;
    const configuracoes = loadConfigurations('configuracoes');
    const empresa = loadCompany();
    const { termos } = this.state;
    return (
      <div>
        <AppBar
          color="default"
          position="static"
        >
          <Tabs
            indicatorColor="primary"
            onChange={this.handleChangeTab}
            //scrollButtons="auto"
            //scrollable
            textColor="primary"
            value={activetab}
          >
            <Tab
              icon={<PublicIcon />}
              label={translations[''] || 'Dados'}

            />
            <Tab
              icon={<PaletteIcon />}
              label="Imagens e Cores"
            // disabled={this.isErrorsEmpty} // Desabilita o Tab se errors não estiver vazio
            //disabled={disablePaxTab} // Desabilita o Tab se errors não estiver vazio

            //style ={{ display: this.getNotDisplay(this.state.isNewRecord)}}              
            />
            <Tab
              icon={<BuildIcon />}
              id="tab3"
              label="Recursos"
            //style ={{ display: this.getNotDisplay(this.state.isNewRecord)}}              
            />
            <Tab
              icon={<MenuBookIcon />}
              id="tab4"
              label="Tradução"
            //style ={{ display: this.getNotDisplay(this.state.isNewRecord)}}              
            />

          </Tabs>
        </AppBar>
        {/* Aba Geral */}
        {activetab === 0 &&
          <TabContainer>
            <CardContent
              style={{ display: this.getNotDisplay(this.state.isNewRecord) }}
            >

              <Typography
                variant="h3"
              >Configuração Sisare Passenger
              </Typography>

              <Typography
                color="textSecondary"
                component="p"
                variant="body2"
              >


              </Typography>

            </CardContent>

            <Box width="100%">

              <TextField
                className={classes.textField}
                style={{ marginRight: "1rem" }}
                //error={this.hasErrorField('Nro. Reserva Cli [File]', os.nroreservaorigcli)}
                //error={!!errors.nroreservaorigcli}
                label="Título da página"
                name="titulo_pagina"
                onChange={this.OnChange}
                type="text"
                value={pagina.titulo_pagina || ''}
                variant="outlined"
                disabled={this.state.isView}

              />

              <TextField
                className={classes.textField}
                //style={{marginBottom:"0.5rem", marginRight:"1rem", width:'50%'}}
                style={{ width: '50%' }}
                label="URL da página"
                name="url_default"
                value={pagina.url_default || ''}
                onChange={this.OnChange}
                error={!!errors.url_default}
                helperText={errors.url_default || ''}
                variant="outlined"
                disabled={this.state.isView}


              />
              
              <TextField
                className={classes.textField}
                //style={{marginBottom:"0.5rem", marginRight:"1rem", width:'50%'}}
                style={{ width: '50%' }}
                label="URL local"
                name="url_local"
                value={pagina.url_local || ''}
                onChange={this.OnChange}
                error={!!errors.url_local}
                helperText={errors.url_local || ''}
                variant="outlined"
                disabled={this.state.isView}


              />
            </Box>

            <TextField
              className={classes.textField}
              //error={this.hasErrorField('Email', os.email)}
              //inputProps={{ maxLength: 50 }} // tamanho máximo permitido
              label="Endereço completo"
              name="endereco_completo"
              onChange={this.OnChange}
              style={{ width: '40%', marginRight: "1rem" }}
              type="text"
              value={pagina.endereco_completo || ''}
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email || ''}
              disabled={this.state.isView}

            />


            <TextField
              className={classes.textField}
              //error={this.hasErrorField('Email', os.email)}
              //inputProps={{ maxLength: 50 }} // tamanho máximo permitido
              label="Fone Contato"
              name="fone_contato"
              onChange={this.OnChange}
              style={{ width: '40%', marginRight: "1rem" }}
              type="text"
              value={pagina.fone_contato || ''}
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email || ''}
              disabled={this.state.isView}

            />


            <TextField
              className={classes.textField}
              //error={this.hasErrorField('Email', os.email)}
              //inputProps={{ maxLength: 50 }} // tamanho máximo permitido
              label="E-mail contato"
              name="email_contato"
              onChange={this.OnChange}
              style={{ width: '40%', marginRight: "1rem" }}
              type="text"
              value={pagina.email_contato || ''}
              variant="outlined"
              error={!!errors.email_contato}
              helperText={errors.email_contato || ''}
              disabled={this.state.isView}
            />

            <TextField
              className={classes.textField}
              //error={this.hasErrorField('Email', os.email)}
              //inputProps={{ maxLength: 50 }} // tamanho máximo permitido
              label="URL Instagram"
              name="url_instagram"
              onChange={this.OnChange}
              style={{ width: '40%', marginRight: "1rem" }}
              type="text"
              value={pagina.url_instagram || ''}
              variant="outlined"
              error={!!errors.url_instagram}
              helperText={errors.url_instagram || ''}
              disabled={this.state.isView}

            />


            <TextField
              className={classes.textField}
              //error={this.hasErrorField('Email', os.email)}
              //inputProps={{ maxLength: 50 }} // tamanho máximo permitido
              label="URL Facebook"
              name="url_facebook"
              onChange={this.OnChange}
              style={{ width: '40%', marginRight: "1rem" }}
              type="text"
              value={pagina.url_facebook || ''}
              variant="outlined"
              error={!!errors.url_facebook}
              helperText={errors.url_facebook || ''}
              disabled={this.state.isView}

            />

          </TabContainer>}
        {/* Aba 1 */}
        {activetab === 1 &&
          <TabContainer>
            <div >
              <Box width="100%" >
                <FormControl
                  //error={this.hasErrorField('idioma')}
                  //fullWidth
                  ///width="10"
                  style={{ width: "40%", maxWidth: "1" }}
                  //style={{ marginTop: '1rem' }}    
                  //variant="filled" 
                  disabled={this.state.isView}

                >

                  <Box width="100%">
                    <TextField
                      className={classes.textField}
                      style={{ marginRight: "1rem" }}
                      //error={this.hasErrorField('Nro. Reserva Cli [File]', os.nroreservaorigcli)}
                      //error={!!errors.nroreservaorigcli}
                      label="Cor primária"
                      name="cor_primaria"
                      onChange={this.OnChange}
                      type="text"
                      value={pagina.cor_primaria || ''}
                      variant="outlined"
                      disabled={this.state.isView}

                    />
                    <TextField
                      className={classes.textField}
                      style={{ marginRight: "1rem" }}
                      //error={this.hasErrorField('Nro. Reserva Cli [File]', os.nroreservaorigcli)}
                      //error={!!errors.nroreservaorigcli}
                      label="Cor secundária"
                      name="cor_secundaria"
                      onChange={this.OnChange}
                      type="text"
                      value={pagina.cor_secundaria || ''}
                      variant="outlined"
                      disabled={this.state.isView}

                    />

                    <TextField
                      className={classes.textField}
                      style={{ width: '80%', marginRight: "1rem" }}
                      //error={this.hasErrorField('Nro. Reserva Cli [File]', os.nroreservaorigcli)}
                      //error={!!errors.nroreservaorigcli}
                      label="URL Logo"
                      name="logo_url"
                      onChange={this.OnChange}
                      type="text"
                      value={pagina.logo_url || ''}
                      variant="outlined"
                      disabled={this.state.isView}
                    />

                    <TextField
                      className={classes.textField}
                      //error={this.hasErrorField('Email', os.email)}
                      //inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                      label="Favicon"
                      name="favicon_url"
                      onChange={this.OnChange}
                      style={{ width: '80%', marginRight: "1rem" }}
                      type="text"
                      value={pagina.favicon_url || ''}
                      variant="outlined"
                      error={!!errors.favicon_url}
                      helperText={errors.favicon_url || ''}
                      disabled={this.state.isView}

                    />


                    <TextField
                      className={classes.textField}
                      //error={this.hasErrorField('Email', os.email)}
                      //inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                      label="URL Imagem Principal [1800 x 700]"
                      name="img_principal_url"
                      onChange={this.OnChange}
                      style={{ width: '80%', marginRight: "1rem" }}
                      type="text"
                      value={pagina.img_principal_url || ''}
                      variant="outlined"
                      error={!!errors.img_principal_url}
                      helperText={errors.img_principal_url || ''}
                      disabled={this.state.isView}
                    />

                    <Typography
                      color="textSecondary"
                      component="p"
                      variant="body2"

                    >Texto imagem principal - Português
                    </Typography>
                    <textarea
                      id="texto_img_principal_ptbr"
                      name="texto_img_principal_ptbr"
                      onChange={this.OnChange}
                      className={classes.textarea}
                      value={pagina.texto_img_principal_ptbr || ''}
                      disabled={this.state.isView}
                    />


                    <Typography
                      color="textSecondary"
                      component="p"
                      variant="body2"

                    >Sub-Texto imagem principal - Português
                    </Typography>
                    <textarea
                      id="subtexto_img_principal_ptbr"
                      name="subtexto_img_principal_ptbr"
                      onChange={this.OnChange}
                      className={classes.textarea}
                      value={pagina.subtexto_img_principal_ptbr || ''}
                      disabled={this.state.isView}
                    />

                    <Typography
                      color="textSecondary"
                      component="p"
                      variant="body2"

                    >Texto imagem principal - Espanhol
                    </Typography>
                    <textarea
                      id="texto_img_principal_escl"
                      name="texto_img_principal_escl"
                      onChange={this.OnChange}
                      className={classes.textarea}
                      value={pagina.texto_img_principal_escl || ''}
                      disabled={this.state.isView}
                    />



                    <Typography
                      color="textSecondary"
                      component="p"
                      variant="body2"

                    >Sub-Texto imagem principal - Espanhol
                    </Typography>
                    <textarea
                      id="subtexto_img_principal_escl"
                      name="subtexto_img_principal_escl"
                      onChange={this.OnChange}
                      className={classes.textarea}
                      value={pagina.subtexto_img_principal_escl || ''}
                      disabled={this.state.isView}
                    />



                    <Typography
                      color="textSecondary"
                      component="p"
                      variant="body2"

                    >Texto imagem principal - Inglês
                    </Typography>
                    <textarea
                      id="texto_img_principal_enus"
                      name="texto_img_principal_enus"
                      onChange={this.OnChange}
                      className={classes.textarea}
                      value={pagina.texto_img_principal_enus || ''}
                      disabled={this.state.isView}
                    />


                    <Typography
                      color="textSecondary"
                      component="p"
                      variant="body2"

                    >Sub-Texto imagem principal - Inglês
                    </Typography>
                    <textarea
                      id="subtexto_img_principal_enus"
                      name="subtexto_img_principal_enus"
                      onChange={this.OnChange}
                      className={classes.textarea}
                      value={pagina.subtexto_img_principal_enus || ''}
                      disabled={this.state.isView}
                    />




                    <TextField
                      className={classes.textField}
                      //error={this.hasErrorField('Email', os.email)}
                      //inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                      id="logo_programacao"
                      label="URL Logo Programação Passageiro "
                      name="logo_programacao"
                      onChange={this.OnChange}
                      style={{ width: '80%', marginRight: "1rem" }}

                      type="text"
                      value={pagina.logo_programacao || ''}
                      variant="outlined"
                      error={!!errors.logo_programacao}
                      helperText={errors.logo_programacao || ''}
                      disabled={this.state.isView}

                    />
                  </Box>


                </FormControl>
                <Paper
                  style={{
                    maxWidth: 160,
                    width: '100%',
                    float: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '0px', // Ajuste o valor conforme necessário para a distância desejada
                    //maxWidth:'100'
                  }}
                >
                </Paper>
              </Box>
            </div>
          </TabContainer>}

        {/* Aba Recursos */}
        {activetab === 2 &&
          <TabContainer>
            <FormControl component="fieldset">
              <FormLabel component="legend">Guiamento de escalas com áudio</FormLabel>

              <FormControlLabel
                control={
                  <Switch
                    checked={pagina.audio_guia === ASIM}
                    name="audio_guia"
                    onChange={this.OnChange}
                    color="primary"
                    disabled={this.state.isView}
                  />
                }
                label={pagina.audio_guia === ASIM ? 'Sim' : 'Não'}
              />
              <Box width="100%">
                <br />
                <TextField
                  className={classes.textField}
                  //style={{ width: '100%', marginRight: "1rem" }}
                  label="URL e porta streaming comunicação"
                  name="url_port_streaming"
                  onChange={this.OnChange}
                  type="text"
                  value={pagina.url_port_streaming || ''}
                  variant="outlined"
                  disabled={this.state.isView}
                />
              </Box>
            </FormControl>
          </TabContainer>}


        {/* Aba Tradução */}
        {activetab === 3 &&
          <TabContainer>
            <FormControl component="fieldset">
              <Card
              //{...rest}
              //className={clsx(classes.root, className)}
              >
                <CardContent>
                  <PerfectScrollbar>
                    <div >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Chave</TableCell>
                            <TableCell>Português BR</TableCell>
                            <TableCell>Espanhol CL </TableCell>
                            <TableCell>Inglês US </TableCell>
                            <TableCell>{translations[CommonTranslations.GRID_COLUMN_ACOES] || 'Ações'}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {termos.slice(0, 1000).map(termo => (
                            <TableRow
                              hover
                              key={termo.chave}
                            >
                              <TableCell >{termo.chave}</TableCell>
                              <TableCell > {termo.termoportuguesbr}
                              </TableCell>
                              <TableCell >{termo.termoespanholcl}</TableCell>
                              <TableCell >{termo.termoinglesus}</TableCell>
                              <TableCell >

                                <Button
                                  color="primary"
                                  onClick={() => this.openTraducaoModal(termo.chave)}
                                >
                                  <EditIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Modal
                        open={this.state.open}
                        onClose={this.handleClose}
                      >
                        <div style={{ margin: '100px auto', width: '80%', padding: '20px', backgroundColor: 'white' }}>
                          <TraducaoSisarePassengerForm
                            traducao={this.state.selectedChaveTraducao} // passa objetoPaxselecionado para o formulário.
                            onClose={this.handleClose}
                            onSave={(updatePax) => {
                              this.handleClose(); // Fechar o modal
                              this.loadTermos();
                            }}
                            isModal={true}
                            chave={this.state.selectedChaveTraducao}
                            id={this.state.selectedChaveTraducao}  // Passe o ID do Pax ou 'novo' para um novo registro
                          />
                        </div>
                      </Modal>

                    </div>
                  </PerfectScrollbar>
                </CardContent>
              </Card>

            </FormControl>
          </TabContainer>}


        <div>
          <ButtonA2X
            onClick={() => { this.handleSubmit() }}
            disabled={this.state.isView}

          >
            Salvar
          </ButtonA2X>

          <ButtonA2X
            //disabled={this.validationRequiredFields()}
            //style ={{ display: 'none'}}
            variant="contained"
            color="secondary"
            onClick={() => { this.onClickCancel() }}
          >
            Cancelar
          </ButtonA2X>


        </div>


      </div>


    );
  }
}

AdmSisarePassengerForm.propTypes =
{
  classes: PropTypes.object.isRequired
};
//export default OSAdmSisarePassenger;
export default withStyles(styles)(AdmSisarePassengerForm);