import React, { Component } from 'react';
import { TextField, Button, Box, Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import api from '../../../services/api';
import { diff } from 'deep-diff';
import moment from 'moment';
import AlertInformation from 'components/AlertInformation/AlertInformation';

// Definição dos estilos
const styles = theme => ({
    textField: {
        marginTop: theme.spacing(0),
        marginBottom: "0.5rem",
        marginRight: "0.5rem",
        variant: "outlined"
    },
    addButton: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        float: 'right',
    },
    modalContent: {
        maxHeight: '100vh', // Ajuste a altura máxima conforme necessário
        overflowY: 'auto', // Permite rolar verticalmente
        width: '50vh'
    }
});

class HorarioPadraoServicoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            horario: {
                codservico: this.props.codServico
            },
            horario_original: {},
            // errors: {},
            isNewRecord: false,
            openAlert: false,
            messageAlert: '',
        };
    }

    async componentDidMount() {
        let id = this.props.id;
        let horarioData;

        if (id > 0) {
            if (this.props.codServico === undefined) {
                horarioData = this.props.horario;
            } else {
                const response = await api.get(`/horarioservico/${id}`);
                horarioData = response.data[0];

                horarioData.dom = moment(horarioData.dom).format("HH:mm");
                horarioData.seg = moment(horarioData.seg).format("HH:mm");
                horarioData.ter = moment(horarioData.ter).format("HH:mm");
                horarioData.qua = moment(horarioData.qua).format("HH:mm");
                horarioData.qui = moment(horarioData.qui).format("HH:mm");
                horarioData.sex = moment(horarioData.sex).format("HH:mm");
                horarioData.sab = moment(horarioData.sab).format("HH:mm");
            }

            this.setState({
                horario: horarioData,
                horario_original: JSON.parse(JSON.stringify(horarioData)),
                isNewRecord: false
            });
        } else if (id === 'novo') {
            this.setState({ isNewRecord: true });
        }
    }

    handleCancel = () => {
        this.props.onClose();
    };

    OnChange = (event) => {
        const { horario } = this.state;
        const { name, value } = event.target;
        let adjustedValue = value;

        this.setState(prevState => ({
            horario: {
                ...prevState.horario,
                [name]: adjustedValue
            },
            errors: {
                ...prevState.errors,
                [name]: typeof adjustedValue === 'string' && adjustedValue.trim() === ''
            }
        }));

    }

    onClickSave = () => {
        if (this.state.isNewRecord) {
            this.insereHorario();
        } else {
            this.atualizaHorario();
        }
    }

    insereHorario = async () => {
        const { horario } = this.state;
        try {
            if (!(this.props.codServico === undefined)) {
                const response = await api.post(`/horarioservico`, horario);
                if (response.status === 200) {
                    this.handleCancel();
                    this.props.onSave();
                }
            } else {
                this.handleCancel();
                this.props.onSave(this.state.horario);
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    atualizaHorario = async () => {
        const { horario, horario_original } = this.state;

        const differences = diff(horario_original, horario);

        if (!differences) {
            this.handleCancel();
            return;
        }

        const horarioAlterado = {};
        differences.forEach(d => {
            if (d.kind === 'E' || d.kind === 'N') {
                horarioAlterado[d.path.join('.')] = d.rhs;
            }
        });

        try {
            if (!(this.props.codServico === undefined)) {
                const response = await api.put(`/horarioservico/${horario.chave}`, horarioAlterado);
                if (response.status === 200) {
                    this.handleCancel();
                    this.props.onSave();
                }
            } else {
                this.handleCancel();
                this.props.onSave(this.state.horario);
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    handleOpenAlert = (message) => {
        this.setState({ openAlert: true, messageAlert: message });
    };

    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    };

    formatHora(hora) {
        if (!(hora === null)) {
            const horaApresentacao = hora;
            const dateObject = new Date(horaApresentacao);
            const formattedTime = dateObject.toLocaleTimeString('pt-BR', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            });
            return formattedTime;
        }
        else {
            return '';
        }
    };

    render() {
        const { horario } = this.state;
        const { isModal,
            classes
        } = this.props;       

        return (
            <Dialog open={isModal} onClose={this.handleCancel}>
                <DialogContent className={classes.modalContent}>

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        label="Domingo"
                        name="dom"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.dom || ''}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        label="Segunda"
                        name="seg"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.seg || ''}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        label="Terça"
                        name="ter"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.ter || ''}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        label="Quarta"
                        name="qua"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.qua || ''}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        label="Quinta"
                        name="qui"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.qui || ''}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        label="Sexta"
                        name="sex"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.sex || ''}
                    />

                    <br />
                    <br />

                    <TextField
                        InputLabelProps={{ shrink: true, required: false }}
                        label="Sábado"
                        name="sab"
                        onChange={this.OnChange}
                        style={{ width: '100px' }}
                        type="time"
                        value={horario.sab || ''}
                    />

                    <br /><br />

                    <Box style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.onClickSave}
                            style={{ marginRight: '5px' }}
                        >
                            Salvar
                        </Button>

                        {isModal && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.handleCancel}
                            >
                                Cancelar
                            </Button>
                        )}
                    </Box>

                    <div>
                        <AlertInformation
                            open={this.state.openAlert}
                            handleClose={this.handleCloseAlert}
                            message={this.state.messageAlert}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(HorarioPadraoServicoForm);