import React, { Component } from 'react';
import { io } from 'socket.io-client';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import api from 'services/api';

// Definição dos estilos
const styles = theme => ({
  textField: {
    marginTop: theme.spacing(0),
    marginBottom: "0.5rem",
    marginRight: "0.5rem",
  },
  addButton: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    float: 'right',
  },
  textarea: {
    width: '100%',
    height: '100px',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
});

class AdmSisarePassengerGuidance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isListening: false,
      dots: '',
      listeners: [],
      recados: [],
      pagina: {},
      isTransmitting: false,
    };

    this.audioContext = null;
    this.audioSource = null;
    this.socket = null;
  }

  estilo = {
    marginLeft: 30,
    marginTop: 30,
    marginRight: 30,
    addButton: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      float: 'right',
    },
  };

  componentDidMount() {
    this.fetchDadosPagina();
    this.getMessageGuide();
    this.initializeSocket();
  }

  componentWillUnmount() {
    this.stopListening();
    if (this.dotInterval) {
      clearInterval(this.dotInterval);
    }
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  // Inicializa o socket e ouve o evento 'isTransmitting'
  initializeSocket = () => {
    const { pagina } = this.state;
    const url_port_streaming = pagina.url_port_streaming;

    if (!url_port_streaming) {
      console.error('URL de streaming não definida!');
      return;
    }

    this.socket = io(url_port_streaming, {
      transports: ['websocket'],
      withCredentials: true,
    });

    const roomId = this.props.match.params.id;
    const nome = "ADM";

    this.socket.emit('joinRoom', roomId, nome);
    console.log(`Conectado à sala: ${roomId} com nome: ${nome}`);

    this.socket.on('isTransmitting', (isTransmitting) => {
      console.log(`Status de transmissão recebido: ${isTransmitting}`);
      this.setState({ isTransmitting });
      if (isTransmitting) {
        console.log("O guia está transmitindo.");
      } else {
        console.log("O guia não está transmitindo.");
      }
    });
  };

  /* busca dados da página */
  fetchDadosPagina = async () => {
    const { idpagina } = this.props.match.params;

    try {
      const response = await api.get(`/appcli/pages/${idpagina}`);
      this.setState({ pagina: response.data[0] }, this.initializeSocket);
    } catch (error) {
      console.error("Erro ao buscar dados da página:", error);
    }
  };

  /* Busca mensagem de guiamento */
  getMessageGuide = async () => {
    const { id } = this.props.match.params;
    try {
      const response = await api.get(`/recado-guia/escala/${id}`);
      this.setState({ recados: response.data });
    } catch (error) {
      console.error('Erro ao buscar recados:', error);
    }
  };

  /* Iniciar audição */
  handleStartListening = (roomId) => {
    if (!this.audioContext) {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    }

    this.socket.on('audioStream', (audioData) => {
      fetch(audioData)
        .then((res) => res.arrayBuffer())
        .then((buffer) => this.audioContext.decodeAudioData(buffer))
        .then((decodedData) => {
          if (this.audioSource) {
            this.audioSource.stop();
          }
          this.audioSource = this.audioContext.createBufferSource();
          this.audioSource.buffer = decodedData;
          this.audioSource.connect(this.audioContext.destination);
          this.audioSource.start(0);
        })
        .catch((error) => {
          console.error('Erro ao reproduzir o áudio:', error);
        });
    });
  };

  stopListening = () => {
    if (this.audioSource) {
      this.audioSource.stop();
      this.audioSource = null;
    }

    if (this.audioContext && this.audioContext.state !== 'closed') {
      this.audioContext.close();
      this.audioContext = null;
    }
  };

  handleListenClick = () => {
    const { isListening } = this.state;
    const { id } = this.props.match.params;

    if (!isListening) {
      this.handleStartListening(id);
      this.setState({ isListening: true });

      this.dotInterval = setInterval(() => {
        this.setState((prevState) => ({
          dots: prevState.dots.length < 3 ? prevState.dots + '.' : '',
        }));
      }, 500);
    } else {
      this.stopListening();
      this.setState({ isListening: false, dots: '' });
      clearInterval(this.dotInterval);
    }
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  formatarData = (dataISO) => {
    const data = new Date(dataISO);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const horas = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');
    return `${dia}/${mes} às ${horas}:${minutos}`;
  };

  renderGuideStatus = () => {
    const { isTransmitting } = this.state;
    console.log('Renderizando guia status:', isTransmitting);
    return (
      <li style={{ color: isTransmitting ? 'red' : 'black' }}>
        {isTransmitting ? 'Guia Online' : 'Guia Offline'}
      </li>
    );
  };

  render() {
    const { isListening, dots, recados } = this.state;
    const { classes } = this.props;

    return (
      <div style={this.estilo}>
        <Typography variant="h4">Transmissão de Guiamento</Typography>
        <Typography>Acompanhe a narração do guia</Typography>
        <div>{this.renderGuideStatus()}</div>

        <Button
          onClick={this.handleListenClick}
          variant="contained"
          color="primary"
          style={{ marginTop: "8px" }}
        >
          {isListening ? `Escutando ${dots}` : 'Escutar'}
        </Button>
        <br/>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "8px" }}
          onClick={this.handleBack}
        >
          Voltar
        </Button>
        <br/><br/>
        <Typography>Recados</Typography>
        <br/>
        <div>
          {recados.map((recado, index) => (
            <div key={index}>
              <h5>{recado.nomeguia} - {this.formatarData(recado.datahoraregistro)}</h5>
              <p>{recado.recado}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AdmSisarePassengerGuidance);
