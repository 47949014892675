import React, { Component } from 'react';
import { TextField, Button, Box, Dialog, DialogContent, FormControl, InputLabel, Select, FormHelperText, FormLabel, Table, TableHead, TableRow, TableCell, Typography, TableBody, Modal } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import api from '../../../services/api';
import { diff } from 'deep-diff';
import moment from 'moment';
import AlertInformation from 'components/AlertInformation/AlertInformation';
import ComboPadrao from 'views/Components/ComboPadrao';
import { ASIM, ATIPOFORNEC_GUIA, ATIPOFORNEC_MOTORISTA, ATIPOFORNEC_OUTROS, ATIPOFORNEC_TRANSPORTE } from 'consts';
import { validateField } from 'lib/functions';
import { getConfigField } from 'lib/configUtils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal/DeleteConfirmationModal';
import CentroDespesaFornecedorServicoForm from './CentroDespesaFornecedorServicoForm';

// Definição dos estilos
const styles = theme => ({
    textField: {
        marginTop: theme.spacing(0),
        marginBottom: "0.5rem",
        marginRight: "0.5rem",
        variant: "outlined"
    },
    addButton: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        float: 'right',
    },
    modalContent: {
        maxHeight: '100vh', // Ajuste a altura máxima conforme necessário
        overflowY: 'auto', // Permite rolar verticalmente
        width: '50vh'
    },
    helperTextError: {
        color: 'red',
    }
});

class FornecedorServicoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fornecedorservico: {
                codservico: this.props.codServico
            },
            fornecedorservico_original: {},
            fornecedores: [],
            centrosdespesa: [],
            isLoadingCentroDespesa: true,
            isLoadingSetor: true,
            isLoadingProjeto: true,
            selectedIdCentroDesp: 0,
            selectedIndexCentroDesp: -1,
            selectedNomeCentroDesp: '',
            isCentroDespDeleteModalOpen: false,
            openCentroDespForm: false,
            selectedCentroDesp: {},
            errors: {},
            schema: {
                codfornecedor: {
                    presence: { allowEmpty: false, message: 'Fornecedor obrigatório' },
                    length: { maximum: 10 },
                    type: 'select-one'
                },
                tipofornec: {
                    presence: { allowEmpty: false, message: 'Tipo obrigatório' },
                    length: { maximum: 10 },
                    type: 'select-one'
                }
            },
            isNewRecord: false,
            openAlert: false,
            messageAlert: ''
        };
    }

    async componentDidMount() {
        let id = this.props.id;
        let fornecedorservicoData;

        const controlaprojeto = (getConfigField('controlaprojeto', 'cfg_financas') == ASIM);

        if (id > 0) {
            if (this.props.codServico === undefined) {
                fornecedorservicoData = this.props.fornecedorservico;
            } else {
                const response = await api.get(`/fornecedorservico/${id}`);
                fornecedorservicoData = response.data[0];
            }

            this.setState({
                fornecedorservico: fornecedorservicoData,
                fornecedorservico_original: JSON.parse(JSON.stringify(fornecedorservicoData)),
                isNewRecord: false
            });

            const fornecedores = await api.get(`/fornecedor/listforchange/${fornecedorservicoData.chave}`);
            this.setState({ fornecedores: fornecedores.data });

            const centrosdespesa = await api.get(`/fornecedorservico/centrocusto/fornecedorservico/${fornecedorservicoData.chave}`);
            this.setState({ centrosdespesa: centrosdespesa.data });

            this.loadCentroDesp();
            this.loadSetor();
            if (controlaprojeto) {
                this.loadProjeto();
            }
        } else if (id === 'novo') {
            this.setState({ isNewRecord: true });

            this.setState(prevState => ({
                fornecedorservico: {
                    ...prevState.fornecedorservico
                }
            }));

            const fornecedores = await api.get(`/fornecedor/active/true`);
            this.setState({ fornecedores: fornecedores.data });
        }
    }

    handleCancel = () => {
        this.props.onClose();
    };

    OnChange = (event) => {
        const { fornecedorservico } = this.state;
        const { name, type, value } = event.target;
        let adjustedValue = Number(value);

        this.setState(prevState => ({
            fornecedorservico: {
                ...prevState.fornecedorservico,
                [name]: adjustedValue
            },
            errors: {
                ...prevState.errors,
                [name]: typeof adjustedValue === 'string' && adjustedValue.trim() === ''
            }
        }));

    }

    onClickSave = () => {
        if (this.state.isNewRecord) {
            this.insereFornecedorServico();
        } else {
            this.atualizaFornecedorServico();
        }
    }

    insereFornecedorServico = async () => {
        const { fornecedorservico } = this.state;
        try {
            if (!(this.props.codServico === undefined)) {
                const response = await api.post(`/fornecedorservico`, fornecedorservico);
                if (response.status === 200) {
                    this.handleCancel();
                    this.props.onSave();
                }
            } else {
                this.handleCancel();
                this.props.onSave(this.state.fornecedorservico);
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    atualizaFornecedorServico = async () => {
        const { fornecedorservico, fornecedorservico_original } = this.state;

        const differences = diff(fornecedorservico_original, fornecedorservico);

        if (!differences) {
            this.handleCancel();
            return;
        }

        const fornecedorservicoAlterado = {};
        differences.forEach(d => {
            if (d.kind === 'E' || d.kind === 'N') {
                fornecedorservicoAlterado[d.path.join('.')] = d.rhs;
            }
        });

        try {
            if (!(this.props.codServico === undefined)) {
                const response = await api.put(`/fornecedorservico/${fornecedorservico.chave}`, fornecedorservicoAlterado);
                if (response.status === 200) {
                    this.handleCancel();
                    this.props.onSave();
                }
            } else {
                this.handleCancel();
                this.props.onSave(this.state.fornecedorservico);
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    }

    validationRequiredFields = () => {
        const { fornecedorservico } = this.state;
        const { schema } = this.state;
        let errors = {};
        const fornecedorError = validateField('codfornecedor', fornecedorservico.codfornecedor, schema);
        if (fornecedorError) {
            errors.codfornecedor = fornecedorError;
        }
        const tipofornecError = validateField('tipofornec', fornecedorservico.tipofornec, schema);
        if (tipofornecError) {
            errors.tipofornec = tipofornecError;
        }
        return errors;
    };

    handleSubmit = () => {
        let errors = this.validationRequiredFields();
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.onClickSave();
        }
    };

    handleOpenAlert = (message) => {
        this.setState({ openAlert: true, messageAlert: message });
    };

    handleCloseAlert = () => {
        this.setState({ openAlert: false });
    };

    loadCentroDesp = async () => {
        const { centrosdespesa } = this.state;
        const centrosList = centrosdespesa;

        const centroCodes = [...new Set(centrosList.map(centro => centro.codcreditodebito))];
        try {
            const centroDesp = await this.fetchCentros(centroCodes);
            this.setState({ centroDesp: centroDesp, isLoadingCentroDespesa: false });
        } catch (error) {
            console.error("Erro ao buscar centros de despesa:", error);
            this.setState({ isLoadingCentroDespesa: false });
        }
    };

    fetchCentros = async (centroCodes) => {
        try {
            const response = await api.post('/financas/centroreceitadespesa/findlist', {
                listid: centroCodes
            });

            const centroDesp = response.data.reduce((acc, centro) => {
                acc[centro.codcreditodebito] = centro.desccreditodebito;
                return acc;
            }, {});
            return centroDesp;
        } catch (error) {
            console.error('Erro ao buscar centros de despesa:', error);
        }
    };

    loadSetor = async () => {
        const { centrosdespesa } = this.state;
        const setoresList = centrosdespesa;

        const setorCodes = [...new Set(setoresList.map(setor => setor.codsetor))];
        try {
            const setor = await this.fetchSetores(setorCodes);
            this.setState({ setor: setor, isLoadingSetor: false });
        } catch (error) {
            console.error("Erro ao buscar setores:", error);
            this.setState({ isLoadingSetor: false });
        }
    };

    fetchSetores = async (setorCodes) => {
        try {
            const response = await api.post('/setor/findlist', {
                listid: setorCodes
            });

            const setor = response.data.reduce((acc, setor) => {
                acc[setor.codsetor] = setor.descsetor;
                return acc;
            }, {});
            return setor;
        } catch (error) {
            console.error('Erro ao buscar setores:', error);
        }
    };

    loadProjeto = async () => {
        const { centrosdespesa } = this.state;
        const projetosList = centrosdespesa;

        const projetoCodes = [...new Set(projetosList.map(projeto => projeto.codprojeto))];
        try {
            const projeto = await this.fetchProjetos(projetoCodes);
            this.setState({ projeto: projeto, isLoadingProjeto: false });
        } catch (error) {
            console.error("Erro ao buscar projetos:", error);
            this.setState({ isLoadingProjeto: false });
        }
    };

    fetchProjetos = async (projetoCodes) => {
        try {
            const response = await api.post('/financas/projeto/findlist', {
                listid: projetoCodes
            });

            const projeto = response.data.reduce((acc, projeto) => {
                acc[projeto.codprojeto] = projeto.descprojeto;
                return acc;
            }, {});
            return projeto;
        } catch (error) {
            console.error('Erro ao buscar projetos:', error);
        }
    };

    /* abre janela de confirmação exclusão de centro de despesa */
    openCentroDespDeleteModal = (idcentrodesp, index, nomecentrodesp) => {
        this.setState({
            selectedIdCentroDesp: idcentrodesp,
            selectedIndexCentroDesp: index
        });
        this.setState({ selectedNomeCentroDesp: nomecentrodesp });
        this.setState({ isCentroDespDeleteModalOpen: true });
    };

    /* fecha janela de confirmação exclusão de centro de despesa */
    closeCentroDespDeleteModal = () => {
        this.setState({ isCentroDespDeleteModalOpen: false });
    };

    /* deleta centro de despesa */
    handleDeleteCentroDesp = async (id, index) => {
        const { isNewRecord } = this.state;
        try {
            if (!(isNewRecord)) {
                const response = await api.delete(`/fornecedorservico/centrocusto/${id}`);
                // Verifica se a exclusão foi bem-sucedida
                if (response.status === 200) {
                    try {
                        this.closeCentroDespDeleteModal();
                        this.updateCentroDespList();
                    } catch (error) {
                        console.error('Erro ao fechar o modal:', error);
                    }
                }
            }
            else {
                this.removeCentroDespEReorganizarSequencia(index);
                this.closeCentroDespDeleteModal();
            }

        } catch (error) {
            this.closeCentroDespDeleteModal();
            this.handleOpenAlert(error.response.data.error);
            console.log(error);
            console.log(error.message);
        }
    };

    // Método para atualizar a lista de centros de despesa
    updateCentroDespList = async (centrodespesa) => {
        const centrodespesaoff = centrodespesa;

        const controlaprojeto = (getConfigField('controlaprojeto', 'cfg_financas') == ASIM);

        try {
            if (!(this.state.isNewRecord)) {
                const centrosdespesa = await api.get(`/fornecedorservico/centrocusto/fornecedorservico/${this.state.fornecedorservico.chave}`);
                if (centrosdespesa.status === 200) {
                    this.setState({ centrosdespesa: centrosdespesa.data });
                }
            }
            else {
                const { centrosdespesa } = this.state;
                const index = centrosdespesa.findIndex(centrodespesa => centrodespesa.chave === centrodespesaoff.chave);

                if (index !== -1) {
                    centrosdespesa[index] = centrodespesaoff;
                    this.setState({ centrosdespesa: centrosdespesa });
                } else {
                    const novoCentroDespesa = centrodespesaoff;
                    novoCentroDespesa.chave = (this.state.centrosdespesa.length + 1);

                    centrosdespesa.push(novoCentroDespesa);

                    // Atualiza o estado com a nova lista de centros de despesa
                    this.setState({ centrosdespesa: centrosdespesa });
                }
            }

            this.loadCentroDesp();
            this.loadSetor();
            if (controlaprojeto) {
                this.loadProjeto();
            }
        } catch (error) {
            this.handleOpenAlert(error.response.data.error);
        }
    };

    /* remover centro de despesa da lista off e atualizar na sequencia */
    removeCentroDespEReorganizarSequencia(index) {
        const { centrosdespesa } = this.state;

        centrosdespesa.splice(index, 1);

        centrosdespesa.map((centrodespesa, idx) => (
            centrosdespesa[idx].chave = idx + 1
        ));

        this.setState({ centrosdespesa: centrosdespesa });
    }

    /* open form centro de despesa modal */
    openCentroDespesaModal = (id = 'novo') => {
        let centrodespesaEncontrado = {};

        if (this.state.isNewRecord) {
            if (!(id === 'novo')) {
                const idcentrodespesaProcurado = parseInt(id, 10);
                centrodespesaEncontrado = this.state.centrosdespesa.find(centrodespesa => centrodespesa.chave === idcentrodespesaProcurado);
            }
        }

        this.setState({
            openCentroDespForm: true,
            selectedIdCentroDesp: id,
            selectedCentroDesp: centrodespesaEncontrado
        });
    };

    handleCentroDespesaFormClose = () => {
        this.setState({ openCentroDespForm: false });
    };

    render() {
        const { isModal, classes } = this.props;

        const { fornecedorservico } = this.state;
        const { fornecedores } = this.state;
        const { centrosdespesa } = this.state;
        const { centroDesp, isLoadingCentroDespesa } = this.state;
        const { setor, isLoadingSetor } = this.state;
        const { projeto, isLoadingProjeto } = this.state;

        const { errors } = this.state;

        // campos configurações
        const controlaprojeto = (getConfigField('controlaprojeto', 'cfg_financas') == ASIM);

        return (
            <Dialog open={isModal} onClose={this.handleCancel} maxWidth="sm" fullWidth>
                <DialogContent
                // className={classes.modalContent}
                >

                    <FormControl
                        fullWidth
                        variant="outlined"
                    >
                        <ComboPadrao
                            name={'codfornecedor'}
                            label={'Fornecedor'}
                            value={fornecedorservico.codfornecedor}
                            onChange={this.OnChange}
                            fieldId={'codfornecedor'}
                            listItems={fornecedores}
                            fieldDescription={'fantasia'}
                            helperText={errors.codfornecedor || ''}
                            error={!!errors.codfornecedor}
                        />
                    </FormControl>

                    <br />
                    <br />

                    <FormControl
                        variant="outlined"
                    >
                        <InputLabel
                            htmlFor="outlined-age-native-simple"
                            className={classes.inputLabel}
                        >
                            Tipo
                        </InputLabel>
                        <Select
                            fullWidth
                            className={classes.select}
                            inputProps={{
                                id: 'outlined-age-native-simple',
                                name: 'tipofornec',
                            }}
                            label="tipo"
                            native
                            onChange={this.OnChange}
                            value={fornecedorservico.tipofornec || ''}
                            style={{ color: 'black', border: 'none' }}
                        >
                            <option value={"undefined"} aria-label="Não Definido"> </option>
                            <option
                                key={ATIPOFORNEC_GUIA}
                                value={ATIPOFORNEC_GUIA}
                                style={{ color: 'black', backgroundColor: 'inherit' }}
                            >
                                Guia
                            </option>
                            <option
                                key={ATIPOFORNEC_TRANSPORTE}
                                value={ATIPOFORNEC_TRANSPORTE}
                                style={{ color: 'black', backgroundColor: 'inherit' }}
                            >
                                Transportista
                            </option>
                            <option
                                key={ATIPOFORNEC_MOTORISTA}
                                value={ATIPOFORNEC_MOTORISTA}
                                style={{ color: 'black', backgroundColor: 'inherit' }}
                            >
                                Motorista
                            </option>
                            <option
                                key={ATIPOFORNEC_OUTROS}
                                value={ATIPOFORNEC_OUTROS}
                                style={{ color: 'black', backgroundColor: 'inherit' }}
                            >
                                Outros
                            </option>
                        </Select>
                        <FormHelperText className={errors.tipofornec ? classes.helperTextError : ''}>
                            {errors.tipofornec}
                        </FormHelperText>
                    </FormControl>

                    <Box style={{ display: ((fornecedorservico.tipofornec === ATIPOFORNEC_OUTROS) ? '' : 'none') }}>
                        <FormLabel
                            component="legend"
                            style={{
                                marginTop: '10px',
                                textAlign: 'center',
                                backgroundColor: '#e0e0e0',
                                padding: '1px',
                            }}
                            disabled={this.state.isView}
                        > Centros de Despesa - Outros Fornecedores
                        </FormLabel>

                        <Table className={''} aria-label="caption table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography
                                            color="textSecondary"
                                            component="p"
                                            variant="body2"
                                        >
                                            Centros de Despesa
                                        </Typography>
                                    </TableCell>

                                    <TableCell>
                                        <Typography
                                            color="textSecondary"
                                            component="p"
                                            variant="body2"
                                        >
                                            Setor
                                        </Typography>
                                    </TableCell>

                                    {controlaprojeto && (
                                        <TableCell>
                                            <Typography
                                                color="textSecondary"
                                                component="p"
                                                variant="body2"
                                            >
                                                Projeto
                                            </Typography>
                                        </TableCell>)
                                    }

                                    <TableCell>
                                        <Typography
                                            color="textSecondary"
                                            component="p"
                                            variant="body2"
                                        >
                                            Ações
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {centrosdespesa.map((centrodespesa, index) => (
                                    <TableRow key={centrodespesa.chave}>
                                        {!isLoadingCentroDespesa && (
                                            <TableCell>
                                                {centroDesp[centrodespesa.codcreditodebito] || ''}
                                            </TableCell>)
                                        }

                                        {!isLoadingSetor && (
                                            <TableCell align="left">
                                                {setor[centrodespesa.codsetor] || ''}
                                            </TableCell>)
                                        }

                                        {controlaprojeto && !isLoadingProjeto && (
                                            <TableCell align="left">
                                                {projeto[centrodespesa.codprojeto] || ''}
                                            </TableCell>
                                        )}

                                        <TableCell>
                                            <Button
                                                color="primary"
                                                onClick={() => this.openCentroDespesaModal(centrodespesa.chave)}
                                            >
                                                <EditIcon />
                                            </Button>

                                            <Button
                                                color="primary"
                                                onClick={() => this.openCentroDespDeleteModal(centrodespesa.chave, index, centroDesp[centrodespesa.codcreditodebito])}
                                            >
                                                <DeleteOutlineIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                color="primary"
                                // className={classes.addButton}
                                variant="contained"
                                onClick={() => this.openFornecedorModal('novo')}
                                disabled={this.state.isView}
                                style={{
                                    backgroundColor: colors.grey[300],
                                    color: 'black',
                                    marginTop: '5px'
                                }}
                            >
                                Inserir
                            </Button>
                        </div> */}

                        <FormLabel
                            component="legend"
                            style={{
                                textAlign: 'center',
                                backgroundColor: '#e0e0e0',
                                padding: '3px',
                                marginTop: '5px'
                            }}
                            disabled={this.state.isView}
                        >
                        </FormLabel>
                    </Box>

                    <br />
                    <br />

                    <Box style={{ float: 'right' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.handleSubmit}
                            style={{ marginRight: '5px' }}
                        >
                            Salvar
                        </Button>

                        {isModal && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.handleCancel}
                            >
                                Cancelar
                            </Button>
                        )}
                    </Box>

                    <div>
                        <AlertInformation
                            open={this.state.openAlert}
                            handleClose={this.handleCloseAlert}
                            message={this.state.messageAlert}
                        />
                    </div>

                    {/* Exclusão de Centro de Despesa */}
                    <DeleteConfirmationModal
                        open={this.state.isCentroDespDeleteModalOpen}
                        handleClose={() => this.closeCentroDespDeleteModal()}
                        itemId={this.state.selectedIdCentroDesp}
                        message={"Confirma exclusão do centro de despesa " + this.state.selectedNomeCentroDesp + '?'}
                        handleDelete={() => this.handleDeleteCentroDesp(this.state.selectedIdCentroDesp, this.state.selectedIndexCentroDesp)}
                    />

                    {/* Form centros de despesa fornecedor serviço */}
                    <Modal
                        open={this.state.openCentroDespForm}
                        onClose={this.handleCentroDespesaFormClose}
                    >
                        <div>
                            <CentroDespesaFornecedorServicoForm
                                centrodespesafornecedorservico={this.state.selectedCentroDesp}
                                onClose={this.handleCentroDespesaFormClose}
                                // onSave={(updateFornecedor) => {
                                //     this.handleFornecedorServicoFormClose(); // Fechar o modal
                                //     this.updateFornecedorList(updateFornecedor); // Atualizar a lista
                                // }}
                                isModal={true}
                                codFornecedorServico={fornecedorservico.chave}
                                id={this.state.selectedIdCentroDesp}
                            />
                        </div>
                    </Modal>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(FornecedorServicoForm);