import React, { Component } from "react";
import { AppBar, Tabs, Tab, Card, CardContent, Typography, Button, TextField } from "@material-ui/core";
import moment from "moment";
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import GroupIcon from '@material-ui/icons/Group';
import { Link } from 'react-router-dom';
import api from "services/api";

class AdmSisarePassengerRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salas: [],
      idPagina : 0,
      currentTab: 0,
      searchFilter: {
        dataEscala: moment().format("YYYY-MM-DD"), // Data inicial definida como a data atual
      },
    };
  }

  componentDidMount() {
    //this.fetchDadosPagina();
    const { id } = this.props.match.params;
    this.setState({ idPagina: id });
        this.fetchSalas();
  }


  // Método para buscar as salas da API
  fetchSalas = async () => {
    const { searchFilter } = this.state;

    try {
      const formattedDate = moment(searchFilter.dataEscala).format("YYYY-MM-DD");
      const params = `?page=1&limit=100&tbdetescala.dataservico=${formattedDate}&tbdetescala.codguia=_gt_0`;

      //params += `${params ? '&' : '?'}tbappclisisarepassenger.titulo_pagina=_like_${searchFilter.titulo_PaginaFilter}`;

      const response = await api.get(`/escala${params}`);
      console.log('o retorno de response ' + JSON.stringify(response))
      this.setState({ salas: response.data.data });
    } catch (error) {
      console.error("Erro ao buscar salas:", error);
    }
  };

  // Método para atualizar o filtro de busca e chamar a API
  onChangeSearch = (event) => {
    const { name, value } = event.target;

    this.setState(
      (prevState) => ({
        searchFilter: {
          ...prevState.searchFilter,
          [name]: value,
        },
      }),
      () => this.fetchSalas() // Chama a API após atualizar o estado
    );
  };

  // Método para mudar de aba (se necessário no futuro)
  handleTabChange = (event, newValue) => {
    this.setState({ currentTab: newValue });
  };

  // Método para entrar em uma sala
  handleEnterRoom = (codigoSala) => {
    console.log(`Entrando na sala: ${codigoSala}`);
    // Aqui você pode adicionar lógica para entrar na sala, como redirecionamento ou envio de requisição
  };

  render() {
    const { salas, currentTab, searchFilter, pagina } = this.state;

    return (
      <div>
        {/* Barra de abas */}
        <AppBar color="default" position="static">
          <Tabs
            indicatorColor="primary"
            value={currentTab}
            onChange={this.handleTabChange}
            aria-label="Navegação de abas"
          >
            <Tab icon={<GroupIcon />} label="Salas" />
          </Tabs>
        </AppBar>
        <br />
        {/* Campo de busca por data */}
        <TextField
          InputLabelProps={{ shrink: true, required: false }}
          inputProps={{ maxLength: 50 }} // tamanho máximo permitido
          label="Data Escala"
          name="dataEscala"
          onChange={this.onChangeSearch} // Atualiza filtro e chama API
          style={{ marginLeft: 20 }}
          type="date"
          value={searchFilter.dataEscala}
        />
        {/* Conteúdo da aba Salas */}
        {currentTab === 0 && (
          <div style={{ padding: "16px" }}>
            {salas.map((sala) => (
              <Card key={sala.coditemescala} style={{ marginBottom: "16px" }}>
                <CardContent>
                  <Typography variant="h6">Escala: {sala.coditemescala}</Typography>
                  <Typography>Serviço: {sala.descservico}</Typography>
                  <Typography>Guia: {sala.nomeguia}</Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    component={Link} // Usa Link como componente
                    to={`/admappcli/adm-sisare-passenger-guidance/${sala.coditemescala}/${this.state.idPagina}`} // Link para a sala
                    style={{ marginTop: "8px" }}
                  >
                    Entrar
                  </Button>

                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default AdmSisarePassengerRoom;
